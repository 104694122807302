<template>
  <div class="w-full">
    <section
      v-if="backgroundUrl"
      class="relative md:mx-8 min-h-[180px] md:h-[640px] h-[30vw] bg-surfaceDark text-onSurfaceDark md:m-8 p-10 md:rounded-[32px] overflow-hidden flex flex-col items-center justify-center bg-center bg-cover"
      :style="backgroundImage"
    >
      <div class="absolute inset-0 bg-surfaceDark opacity-60 z-10"></div>
      <h1
        v-if="title"
        class="relative z-20 pb-2 text-white text-title md:text-title"
        v-html="title"
      />
      <h2 class="relative z-20 text-body-medium text-primary">
        {{ subtitle }}
      </h2>
    </section>
  </div>
</template>

<script>
export default {
  name: "BigHeroBannerSection",
  props: {
    backgroundUrl: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
    },
  },
  computed: {
    backgroundImage() {
      return {
        backgroundImage: `url(${this.backgroundUrl})`,
      };
    },
  },
};
</script>
