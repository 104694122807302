<template>
  <div class="flex flex-col items-center w-full flex-[1_0_auto]">
    <navigation-bar />
    <div v-if="isLoading" class="w-full">
      <hero-banner :background-url="null" :title="null" />
    </div>
    <div v-else class="flex-[1_0_auto] w-full">
      <div v-if="page">
        <hero-banner
          v-if="page.titel"
          :background-url="null"
          :title="page.titel"
        />
        <div>
          <component
            v-for="(section, index) in page.content"
            :key="index"
            :is="componentMapping[section.__component]"
            v-bind="section"
            :title="section.titel"
            :subtitle="section.ondertitel"
            :text="section.tekst ?? section.uitleg"
            :content="section.content"
            :button="section.knop"
            :images="[section.foto_1?.url, section.foto_2?.url]"
          />
        </div>
      </div>
      <page-not-found-section v-else />
    </div>
  </div>
  <site-footer />
</template>

<script>
import { defineComponent, ref, onMounted } from "vue";
import axios from "axios";
import { baseUrl } from "@/config";
import NavigationBar from "@/components/globals/NavigationBar.vue";
import SiteFooter from "@/components/SiteFooter.vue";
import HeroBanner from "@/components/HeroBanner.vue";
import WysiwygSection from "@/components/globals/sections/WysiwygSection.vue";
import DoubleImageSection from "@/components/globals/sections/DoubleImageSection.vue";
import CallToActionSection from "@/components/globals/sections/CallToActionSection.vue";
import PageNotFoundSection from "@/components/globals/sections/PageNotFoundSection.vue";

export default defineComponent({
  name: "ContentPage",
  components: {
    NavigationBar,
    SiteFooter,
    HeroBanner,
    WysiwygSection,
    DoubleImageSection,
    CallToActionSection,
    PageNotFoundSection,
  },
  props: {
    slug: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const page = ref(null);
    const isLoading = ref(true);

    const fetchPage = async () => {
      try {
        const response = await axios.get(
          `${baseUrl}/api/content-paginas?filters[slug][$eq]=${props.slug}&pLevel=3`
        );
        page.value = response.data?.data[0] || null;
      } catch (error) {
        console.error("Error fetching the page:", error);
      } finally {
        isLoading.value = false;
      }
    };

    const componentMapping = {
      "secties.tekst-sectie": WysiwygSection,
      "secties.foto-sectie": DoubleImageSection,
      "secties.dubbele-foto-sectie": DoubleImageSection,
      "secties.call-to-action-gecentreerd": CallToActionSection,
    };

    onMounted(fetchPage);

    return {
      page,
      isLoading,
      componentMapping,
    };
  },
});
</script>
