<template>
  <section
    class="relative mt-16 grid grid-cols-1 md:grid-cols-2 w-full max-w-screen px-6 md:px-[96px] gap-3"
  >
    <div v-for="(image, index) in images" :key="index" class="min-w-[240px]">
      <pressable-wrapper class="rounded-3xl overflow-hidden aspect-[16/9]">
        <img class="w-full h-full object-cover" :src="image" alt="Image" />
      </pressable-wrapper>
    </div>
  </section>
</template>

<script>
import PressableWrapper from "@/components/globals/Pressable.vue";

export default {
  name: "DoubleImageSection",
  components: {
    PressableWrapper,
  },
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style></style>
