import qs from "qs";
import { baseUrl } from "@/config";

const Casesquery = qs.stringify({
  pLevel: 6,
});

export const fetchCases = async () => {
  const url = new URL("/api/portfolio?", baseUrl);
  url.search = Casesquery;

  try {
    const response = await fetch(url.href, { cache: "no-store" });
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error(error);
  }
};

export const fetchCase = async (slug) => {
  const url = new URL(
    `/api/cases?pLevel=3&filters[slug][$eq]=${slug}`,
    baseUrl
  );

  try {
    const response = await fetch(url.href, { cache: "no-store" });
    const data = await response.json();
    console.log("🎾🎾", data.data[0]);
    return data.data[0];
  } catch (error) {
    console.error(error);
  }
};
