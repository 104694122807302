<template>
  <vue-flip
    :active-hover="!isMobile"
    :active-click="isMobile"
    class="w-full h-full aspect-square"
  >
    <!-- FRONT -->
    <template v-slot:front>
      <div
        class="relative w-full h-full flex items-end rounded-3xl overflow-hidden"
      >
        <!-- BACKGROUND IMAGE -->

        <img
          v-if="employee.foto"
          class="absolute object-cover h-full w-full"
          :src="employee.foto.url"
          :alt="employee.naam"
        />
        <div
          class="absolute object-cover h-full w-full bg-surfaceDark"
          v-else
        ></div>

        <!-- IMAGE FILTER -->
        <div class="image-filter" />
        <!-- NAME AND SUBTITLE -->
        <div class="relative bottom-0 left-0 p-6">
          <h3 class="text-label-large text-onSurfaceDark pb-3">
            {{ employee.naam }}
          </h3>
          <p
            v-if="employee.functie"
            class="text-body-medium text-onSurfaceDark"
          >
            {{ employee.functie }}
          </p>
        </div>
      </div>
    </template>
    <!-- BACK -->
    <template v-slot:back>
      <div
        class="relative w-full h-full bg-surfaceDark rounded-3xl p-6 flex flex-col justify-between"
      >
        <!-- ABOUT ME -->
        <div class="div">
          <p class="text-label-small text-onSurfaceDark pb-1">over mij</p>
          <div class="flex flex-wrap gap-2 max-h-[75px] overflow-hidden">
            <employee-card-tag
              v-for="tag in employee.hobbies"
              :key="tag.tekst"
              :icon="tag?.illustratie?.url"
              :text="tag.tekst"
            />
          </div>
        </div>
        <!-- FAVOURITE SONG -->
        <div class="">
          <p class="text-label-small text-onSurfaceDark pb-1">
            Favoriete nummer:
          </p>
          <iframe
            style="border-radius: 12px"
            :style="{ height: iframeHeight }"
            :src="`https://open.spotify.com/embed/track/${employee.favoriete_nummer}?utm_source=generator`"
            width="100%"
            frameBorder="0"
            allowfullscreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </template>
  </vue-flip>
</template>

<script>
import { VueFlip } from "vue-flip";
import EmployeeCardTag from "@/components/EmployeeCardTag.vue";
import { computed } from "vue";
import { useWindowSize } from "@vueuse/core";

export default {
  name: "EmployeeCard",
  props: {
    employee: {
      type: Object,
      required: true,
    },
  },
  components: {
    "vue-flip": VueFlip,
    EmployeeCardTag,
  },
  setup() {
    const { width } = useWindowSize();

    const isMobile = computed(() => width.value < 860);
    console.log("🧀", isMobile);
    return {
      isMobile: isMobile,
    };
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    iframeHeight() {
      if (this.windowWidth < 760) return "152px";
      if (this.windowWidth < 916) return "112px";
      return "152px";
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateWindowWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateWindowWidth);
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>

<style scoped>
.image-filter {
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 12%, rgba(0, 0, 0, 0) 25%);
  @apply absolute w-full h-full opacity-45;
}
</style>
