import { baseUrl } from "@/config";

const API_URL = `${baseUrl}/api/formuliers`; // Gebruik baseUrl voor de Strapi URL

export async function submitForm(payload) {
  try {
    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: payload }),
    });

    if (!response.ok) {
      throw new Error(`Failed to submit the form: ${response.statusText}`);
    }

    const data = await response.json();
    return data; // Retourneer de response als het nodig is
  } catch (error) {
    console.error("Error in submitForm:", error);
    throw error; // Laat de fout door de aanroeper afhandelen
  }
}
