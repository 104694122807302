<template>
  <section
    class="relative pt-[128px] md:pt-[92px] px-6 md:px-[96px] max-w-screen w-full overflow-hidden"
  >
    <h3 v-html="title" class="text-primary text-label-large pb-2" />
    <h2
      v-html="subtitle"
      class="text-headline-medium max-w-[400px] text-onSurfaceLight md:w-full w-[85%]"
    />
    <div class="absolute top-8 md:top-12 -right-12 flex items-center">
      <p
        v-html="monkeyText"
        class="text-body-medium bg-background p-2 rounded-lg border-2 border-onSurfaceVariant text-onSurfaceLight transform transition-all duration-300 ease-in-out"
        :class="{
          'opacity-100 translate-y-0': hovered,
          'opacity-0 translate-y-4': !hovered,
        }"
      />
      <Vue3Lottie
        class="-rotate-[60deg]"
        ref="lottieAnimation"
        :animationData="aapjeJSON"
        :loop="0"
        :height="175"
        :width="175"
        :autoPlay="false"
        @mouseenter="playAnimation"
        @mouseleave="reverseAnimation"
      />
    </div>
    <!-- case container -->
    <div class="relative w-full">
      <Carousel :wrapAround="true" ref="carousel">
        <!-- slide -->
        <Slide v-for="item in cases" :key="item.id">
          <div
            class="relative aspect-[1.3] md:aspect-[1.7] w-full flex items-center flex-col md:flex-row gap-6"
          >
            <!-- images -->
            <div
              v-if="item.homescreen_content"
              class="relative md:absolute md:right-0 md:left-[30%] h-full md:aspect-auto aspect-[1.3]"
            >
              <!-- Image 1 -->
              <img
                v-if="item.homescreen_content?.mobile"
                :src="item.homescreen_content?.mobile.url"
                :alt="item.homescreen_content.mobile.alt"
                class="absolute left-[24%] md:top-4 bottom-0 md:bottom-auto md:max-h-[60%] max-h-[70%] z-30 md:left-[13%] animate-none"
              />
              <!-- Image 2 -->
              <img
                v-if="item.homescreen_content?.tablet"
                :src="item.homescreen_content.tablet.url"
                :alt="item.homescreen_content.tablet.alt"
                class="absolute right-0 w-[60%] my-auto inset-y-1/2 z-20 md:z-10 animate-none"
              />
              <!-- Image 3 -->
              <img
                v-if="item.homescreen_content?.laptop"
                :src="item.homescreen_content.laptop.url"
                :alt="item.homescreen_content.laptop.alt"
                class="absolute left-0 bottom-auto md:bottom-0 max-w-[65%] z-10 md:z-20 animate-none"
              />
            </div>
            <!-- text -->
            <div
              class="md:max-w-[30%] z-40 left-0 flex flex-col items-start text-left"
            >
              <h3 class="text-headline-medium text-primary pb-[6px]">
                {{ item.homescreen_content.titel }}
              </h3>
              <p class="text-body-medium text-onSurfaceLight pb-8">
                {{ item.homescreen_content.ondertitel }}
              </p>
              <pressable-button
                label="Bekijk case"
                :onTap="() => $router.push(`/cases/${item.slug}`)"
              />
            </div>
          </div>
        </Slide>
      </Carousel>
    </div>
    <div class="flex justify-center pt-8 items-center">
      <div class="cursor-pointer rounded-full hover:bg-surfaceHover">
        <svg
          @click="prevCase"
          width="64"
          height="64"
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M26.6667 42.6665L16 31.9998M16 31.9998L26.6667 21.3332M16 31.9998L48 31.9998"
            stroke="#00D07D"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <a
        href="/cases"
        class="text-body-medium md:text-label-large text-onSurfaceLight mx-8"
      >
        meer zien?
      </a>
      <div class="cursor-pointer rounded-full hover:bg-surfaceHover">
        <svg
          @click="nextCase"
          width="64"
          height="64"
          viewBox="0 0 64 64"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M37.3333 42.6665L48 31.9998M48 31.9998L37.3333 21.3332M48 31.9998L16 31.9998"
            stroke="#00D07D"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from "vue";

//monkey
const hovered = ref(false);
const lottieAnimation = ref(null);
//carousel
const carousel = ref(null);

const playAnimation = () => {
  hovered.value = true;
  lottieAnimation.value.setSpeed(2.5);
  lottieAnimation.value.setDirection("forward");
  lottieAnimation.value.play();
};
const reverseAnimation = () => {
  hovered.value = false;
  lottieAnimation.value.setDirection("reverse");
  lottieAnimation.value.play();
};

const nextCase = () => {
  carousel.value.next();
};

const prevCase = () => {
  carousel.value.prev();
};
</script>

<script>
import aapjeJSON from "@/assets/monkey.json";
import PressableButton from "./buttons/PressableButton.vue";
import { Carousel, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default {
  methods: {},
  name: "HomeScreenCasesSection",
  components: {
    PressableButton,
    Carousel,
    Slide,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    monkeyText: {
      type: String,
      required: true,
    },
    cases: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      aapjeJSON,
    };
  },
};
</script>
