<template>
  <section
    class="relative mt-16 flex flex-col md:flex-row w-full max-w-screen px-6 md:px-[96px] gap-3"
  >
    <div v-if="title && text" class="flex flex-col">
      <h2
        v-html="title"
        class="text-headline-medium text-onSurfaceLight mb-3"
      />
      <p
        v-html="formatNewLines(text)"
        class="text-body-medium text-onSurfaceLight"
      />
    </div>
    <div v-if="image" class="min-w-[240px] basis-[33%] flex-shrink-0">
      <pressable-wrapper class="rounded-3xl overflow-hidden">
        <img
          class="aspect-square w-full h-full object-cover"
          :src="image"
          alt="Image"
        />
      </pressable-wrapper>
    </div>
  </section>
</template>

<script>
import PressableWrapper from "@/components/globals/Pressable.vue";

export default {
  name: "TextImageHorSection",
  components: {
    PressableWrapper,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
  },
};
</script>
