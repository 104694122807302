<template>
  <div v-if="home" class="flex flex-col items-center w-full flex-[1_0_auto]">
    <component
      v-for="(section, index) in home.content"
      :key="index"
      :is="componentMapping[section.__component]"
      v-bind="section"
      :title="section.titel"
      :subtitle="section.ondertitel"
      :primaryButton="section.primaire_knop"
      :secondaryButtons="section.tekst_knop"
      :text="section.tekst ?? section.uitleg"
      :clients="section.clients"
      :services="section.services"
      :monkeyText="section.aapje_tekst"
      :leaderboardStats="section"
    />
  </div>
  <div v-else class="flex flex-col items-center w-full flex-[1_0_auto]">
    <HomeScreenHeroSection
      :title="null"
      :subtitle="null"
      :primaryButton="null"
      :secondaryButtons="[]"
    />
  </div>
  <site-footer />
</template>

<script>
import HomeScreenHeroSection from "@/components/HomeScreenHeroSection.vue";
import HomeScreenClientsSection from "@/components/HomeScreenClientsSection.vue";
import HomeScreenServicesSection from "@/components/HomeScreenServicesSection.vue";
import HomeScreenCasesSection from "@/components/HomeScreenCasesSection.vue";
import HomeScreenLeaderboardSection from "@/components/HomeScreenLeaderboardSection.vue";
import SiteFooter from "@/components/SiteFooter.vue";
import { useHomeStore } from "@/stores/home";
import { onMounted, computed } from "vue";
import { useHead } from "@unhead/vue";

export default {
  name: "home-page",
  components: {
    HomeScreenHeroSection,
    HomeScreenClientsSection,
    HomeScreenServicesSection,
    HomeScreenCasesSection,
    HomeScreenLeaderboardSection,
    SiteFooter,
  },
  setup() {
    const homeStore = useHomeStore();
    const componentMapping = {
      "home.hero-sectie": "HomeScreenHeroSection",
      "home.clients-sectie": "HomeScreenClientsSection",
      "home.services-sectie": "HomeScreenServicesSection",
      "home.cases-sectie": "HomeScreenCasesSection",
      "home.leaderboard-sectie": "HomeScreenLeaderboardSection",
    };

    onMounted(async () => {
      await homeStore.loadHome();
    });
    useHead({
      title: "Codios - Web & App development Alkmaar",
    });
    const home = computed(() => homeStore.home);
    console.log("🧀", home?.value);
    if (home.value) {
      console.log("🍕");
    }

    return {
      home,
      componentMapping,
    };
  },
};
</script>

<style>
html,
body {
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
}
</style>
