<template>
  <section class="mt-16 max-w-[720px] md:mx-[96px] mx-6 px-6 md:px-0 w-full">
    <img
      :src="image"
      alt="Image not found"
      class="object-contain w-full aspect-[16/9]"
    />
  </section>
</template>

<script>
export default {
  name: "BigImageSection",
  props: {
    image: {
      type: String,
      required: true,
    },
  },
};
</script>