<template>
  <section
    class="max-w-[578px] flex flex-col items-center text-center pt-[92px] mx-auto"
  >
    <h2
      v-if="text"
      v-html="text"
      class="text-headline-medium text-onSurfaceLight mb-8"
    />
    <pressable-button
      v-if="button"
      :label="button.label"
      :onTap="() => $router.push(button.url)"
    />
  </section>
</template>

<script>
import PressableButton from "@/components/buttons/PressableButton.vue";
export default {
  name: "CallToActionSection",
  components: {
    PressableButton,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    button: {
      type: Object,
      required: true,
    },
  },
};
</script>
