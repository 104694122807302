<template>
  <div class="flex flex-col items-center w-full flex-[1_0_auto]">
    <navigation-bar />
    <hero-banner
      v-if="content"
      :title="content.titel"
      :backgroundUrl="content?.achtergrond_foto?.url"
    />
    <div v-if="content?.services?.length" class="w-full">
      <service-switcher
        :services="content.services"
        :selected="selectedService"
        @service-selected="selectService"
      />
    </div>
    <div v-if="selectedService?.content">
      <component
        v-for="(section, index) in selectedService.content"
        :key="index"
        :is="componentMapping[section.__component]"
        v-bind="section"
        :title="section.titel"
        :subtitle="section.ondertitel"
        :text="section.tekst ?? section.uitleg"
      />
    </div>
  </div>
  <site-footer />
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import SiteFooter from "@/components/SiteFooter.vue";
import NavigationBar from "@/components/globals/NavigationBar.vue";
import HeroBanner from "@/components/HeroBanner.vue";
import ServiceSwitcher from "@/components/ServiceSwitcher.vue";
import { useServicesStore } from "@/stores/services";
import TextRightAlignedSection from "@/components/globals/sections/TextRightAlignedSection.vue";
import TextLeftAlignedSection from "@/components/globals/sections/TextLeftAlignedSection.vue";
import DoubleImageSection from "@/components/globals/sections/DoubleImageSection.vue";
import TextImageHorSection from "@/components/globals/sections/TextImageHorSection.vue";
import { useHead } from "@unhead/vue";

export default {
  name: "HomePage",
  components: {
    SiteFooter,
    NavigationBar,
    HeroBanner,
    ServiceSwitcher,
    TextRightAlignedSection,
    TextLeftAlignedSection,
    DoubleImageSection,
    TextImageHorSection,
  },
  setup(props, { emit }) {
    const selectedService = ref(null);
    const servicesStore = useServicesStore();

    const componentMapping = {
      "secties.tekst-met-titel-rechts": "TextRightAlignedSection",
      "secties.tekst-met-titel-links": "TextLeftAlignedSection",
      "secties.dubbele-foto-secties": "DoubleImageSection",
      "secties.tekst-met-foto-horizontaal": "TextImageHorSection",
    };

    const content = computed(() => servicesStore.services);

    const selectService = (service) => {
      selectedService.value = service;
      emit("service-selected", service);
      window.location.hash = service.naam.toLowerCase().replace(/\s+/g, "-");
      setHead(service);
    };

    const setHead = (service) => {
      useHead({
        title: computed(() => "Codios - " + service.naam),
      });
    };

    const isSelected = (service) => {
      return selectedService.value?.id === service.id;
    };

    const checkHash = () => {
      const hash = window.location.hash.replace("#", "");
      if (hash && content.value?.services?.length) {
        const matchedService = content.value.services.find(
          (service) => service.naam.toLowerCase().replace(/\s+/g, "-") === hash
        );
        if (matchedService) {
          selectedService.value = matchedService;
          emit("service-selected", matchedService);
        }
      } else if (content.value?.services?.length) {
        selectedService.value = content.value.services[0];
        emit("service-selected", content.value.services[0]);
      }
    };

    onMounted(async () => {
      await servicesStore.loadServices();
      checkHash();
      window.addEventListener("hashchange", checkHash);
      setHead(selectedService.value);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("hashchange", checkHash);
    });

    return {
      content,
      componentMapping,
      selectedService,
      selectService,
      isSelected,
    };
  },
};
</script>
