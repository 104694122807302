<template>
  <footer
    class="bg-surfaceDark text-onSurfaceDark p-12 mt-[96px] w-full flex-shrink-0"
  >
    <div class="max-w-[1440px] mx-auto w-full">
      <div
        class="flex flex-col md:flex-row mb-10 justify-between items-center md:items-start content-center gap-12 md:gap-6"
      >
        <img
          v-if="isMobile"
          class="h-[42px] mb-4 object-contain"
          src="@/assets/codios-light.png"
          alt="Codios logo"
        />
        <!-- contact banner -->
        <div
          v-if="footer && footer.call_to_action"
          class="max-w-[460px] flex flex-col md:items-start items-center"
        >
          <h4
            v-if="footer && footer.call_to_action"
            v-html="footer?.call_to_action?.titel"
            class="text-label-large text-primary pb-3 md:text-left text-center"
          />
          <h3
            v-if="footer && footer.call_to_action"
            v-html="footer.call_to_action.ondertitel"
            class="text-headline-small pb-6 md:text-left text-center"
          />
          <pressable-button
            v-if="footer && footer.call_to_action.knop"
            :label="footer.call_to_action.knop.label"
            :onTap="() => $router.push(footer.call_to_action.knop.url)"
          />
        </div>
        <!-- contact info -->
        <div
          v-if="footer"
          class="flex flex-col md:justify-end md:text-right md:max-w-[400px] w-full"
        >
          <img
            v-if="!isMobile"
            class="h-[42px] mb-12 object-contain justify-end flex self-end"
            src="@/assets/codios-light.png"
            alt="Codios logo"
          />
          <a
            class="text-body-medium text-surfaceShadow mb-2.5"
            v-for="telefoonnummer in footer?.contactinformatie?.telefoonnummers"
            :href="`tel:${telefoonnummer.telefoonnummer}`"
            :key="telefoonnummer.label"
            >{{ telefoonnummer.label }}</a
          >
          <a
            class="text-body-medium text-surfaceShadow mb-2.5"
            v-for="email in footer?.contactinformatie?.emailaddressen"
            :href="`mailto:${email.email}`"
            :key="email.label"
            >{{ email.label }}</a
          >
          <p
            v-if="footer?.contactinformatie?.adres"
            v-html="formatNewLines(footer?.contactinformatie?.adres)"
            class="text-body-medium text-surfaceShadow mb-6"
          />
          <!-- socials -->
          <div
            v-if="
              footer?.contactinformatie?.socials &&
              footer.contactinformatie.socials.length > 0
            "
            class="flex gap-6 md:justify-end"
          >
            <a
              v-for="social in footer?.contactinformatie?.socials"
              :href="social.url"
              :key="social.logo.hash"
            >
              <img class="h-10" :src="social.logo.url" alt="" />
            </a>
          </div>
        </div>
      </div>
      <!-- footer links -->
      <div
        v-if="footer && footer.linkjes"
        class="flex md:justify-end md:flex-row flex-col md:items-end md:flex-wrap gap-6 text-body-medium text-onSurfaceDarkVariant"
      >
        <a
          v-for="link in footer.linkjes"
          :key="link.id"
          :href="link.slug"
          v-html="link.titel"
        />
      </div>
    </div>
  </footer>
</template>

<script>
import { useFooterStore } from "@/stores/footer";
import PressableButton from "./buttons/PressableButton.vue";
import { useWindowSize } from "@vueuse/core";
import { onMounted, computed } from "vue";

export default {
  components: { PressableButton },
  name: "site-footer",
  setup() {
    const { width } = useWindowSize();

    const footerStore = useFooterStore();
    onMounted(async () => {
      await footerStore.loadFooter();
    });

    const footer = computed(() => footerStore.footer);
    const isMobileView = computed(() => width.value < 768);
    return {
      footer: footer,
      width: width,
      isMobile: isMobileView,
    };
  },
};
</script>
