<template>
  <div class="flex flex-col w-full basis-1/2 grow max-w-[600px]">
    <h2 class="text-headline-medium w-full max-w-[320px] md:max-w-full mb-3">
      Of vul het formulier in.
    </h2>
    <!-- form -->
    <form class="flex flex-col" @submit.prevent="submitForm">
      <div class="flex flex-col gap-4 md:flex-row md:pb-8 pb-2">
        <input-form v-model="formData.naam" class="w-full" label="Naam" />
        <input-form
          v-model="formData.bedrijf"
          class="w-full"
          label="Bedrijf/organisatie"
        />
      </div>

      <div class="flex flex-col gap-4 md:flex-row md:pb-8 pb-2">
        <input-form v-model="formData.email" label="E-mailadres" />
        <input-form v-model="formData.telefoon" label="Telefoonnummer" />
      </div>

      <input-form
        v-model="formData.bericht"
        class="mb-4"
        label="Je bericht"
        textarea
      />

      <div
        v-if="formSubmitted"
        class="p-4 bg-primary mb-4 rounded-lg transition-opacity duration-500 ease-in-out"
        :class="{ 'opacity-0': !formSubmitted, 'opacity-100': formSubmitted }"
      >
        <p class="text-body-medium text-onPrimary">Je bericht is verzonden!</p>
      </div>
      <div class="flex justify-end">
        <pressable-button
          class="max-w-[140px] relative"
          label="Verstuur"
          type="submit"
        />
        <!-- TODO: implement recaptchav2 on send -->
      </div>
    </form>
  </div>
</template>

<script>
import PressableButton from "@/components/buttons/PressableButton.vue";
import InputForm from "@/components/globals/InputForm.vue";
import { ref } from "vue";
import { submitForm } from "@/services/formService";

const formData = ref({
  naam: "",
  bedrijf: "",
  email: "",
  telefoon: "",
  bericht: "",
});

const formSubmitted = ref(false);

export default {
  name: "ContactScreenForm",
  components: {
    PressableButton,
    InputForm,
  },
  data() {
    return {
      formData,
      formSubtmitted: formSubmitted,
    };
  },
  methods: {
    async submitForm() {
      // You can replace this with your desired form submission logic
      console.log("Formuliergegevens:", formData);
      const result = await submitForm(this.formData); // Service aanroepen
      console.log("Formulier succesvol verzonden:", result);
      this.formSubmitted = true;
      this.resetform();
    },
    resetform() {
      this.formData = {
        naam: "",
        bedrijf: "",
        email: "",
        telefoon: "",
        bericht: "",
      };
    },
  },
};
</script>
