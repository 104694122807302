<template>
  <section
    class="flex mt-16 gap-3 md:gap-[72px] flex-col md:flex-row relative max-w-[1096px] md:mx-[96px] mx-6"
  >
    <h2
      v-if="title"
      v-html="formatNewLines(title)"
      class="text-headline-medium text-onSurfaceLight max-w-[320px]"
    />
    <p
      v-if="formatNewLines(text)"
      v-html="text"
      class="text-body-medium text-onSurfaceLight"
    />
  </section>
</template>

<script>
export default {
  name: "TextRightAlignedSection",
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
