<template>
  <pressable
    class="cursor-pointer bg-cover bg-blend-normal bg-center rounded-3xl overflow-hidden aspect-[16/9] flex justify-center items-center"
    :style="{
      backgroundImage: `linear-gradient(rgba(15, 35, 42, 0.3) 30%, rgba(15, 35, 42, 0.85) 85%), url(${background})`,
    }"
    :onTap="onTap"
  >
    <h3 class="text-headline-large text-onSurfaceDark text-center">
      {{ title }}
    </h3>
    <pressable-arrow :onTap="onTap" class="absolute top-5 right-9" />
  </pressable>
</template>

<script>
import PressableArrow from "./buttons/PressableArrow.vue";
import Pressable from "./globals/Pressable.vue";
export default {
  components: { Pressable, PressableArrow },
  name: "serviceCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    background: {
      type: String,
      required: true,
    },
    onTap: {
      type: Function,
      required: true,
    },
  },
};
</script>
